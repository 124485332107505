<template>
  <div>
    <div class="container-title mt-4 text-center">
      <div>
        {{
          $getVisibleNames("manual.matriz_4", true, "Matriz 4").toUpperCase()
        }}
      </div>
    </div>

    <!-- Matriz de Capacidades -->
    <b-table-simple bordered responsive class="table-capacity mt-3 mb-5">
      <b-thead>
        <b-tr>
          <b-th
            class="primary-cell-matrix4 competence-cell"
            style="
              width: 30%;
              background-color: var(--primary-color) !important;
            "
            >{{
              $getVisibleNames("mesh.competence", true, "Competencias")
            }}</b-th
          >
          <b-th
            class="primary-cell-matrix4"
            style="
              width: 15%;
              background-color: var(--primary-color) !important;
            "
            >{{
              $getVisibleNames(
                "mesh.formationline",
                false,
                "Línea De Formación"
              )
            }}</b-th
          >
          <b-th
            class="primary-cell-matrix4"
            style="
              width: 20%;
              background-color: var(--primary-color) !important;
            "
            >{{
              $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
            }}</b-th
          >
          <b-th
            class="primary-cell-matrix4"
            style="width: 2%; background-color: var(--primary-color) !important"
            >Nivel</b-th
          >
          <b-th
            class="primary-cell-matrix4"
            v-for="capacity in getListCapacities"
            :key="'capacity' + capacity.id"
          >
            {{ capacity.name }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="egress_profile_matter in egressProfileMattersFilter"
          :key="egress_profile_matter.id"
        >
          <b-td class="secondary-cell-matrix4 text-justify">
            <div
              v-for="competence in egress_profile_matter.competences"
              :key="'competence' + competence"
            >
              <p>{{ getCompetenceName(competence) }}</p>
            </div>
          </b-td>
          <b-td class="secondary-cell-matrix4">
            {{ getFormationLineName(egress_profile_matter.formation_line) }}
          </b-td>
          <b-td class="secondary-cell-matrix4">
            <b-link
              class="secondary-cell-matrix4"
              :to="{
                name: 'MatterProgramView',
                params: { matter_id: egress_profile_matter.id },
              }"
              v-b-tooltip.v-secondary.noninteractive="
                `Ir al ${$getVisibleNames(
                  'manual.matter_program',
                  false,
                  'Programa de la Asignatura'
                )}`
              "
            >
              {{ getMatterName(egress_profile_matter.matter) }}
            </b-link>
          </b-td>
          <b-td class="secondary-cell-matrix4">
            {{ egress_profile_matter.plan_level }}
          </b-td>
          <b-td
            class="secondary-cell-matrix4"
            v-for="capacity in getListCapacities"
            :key="'capacity_matter' + capacity.id"
          >
            <div v-if="egress_profile_matter.capacities.includes(capacity.id)">
              <b-icon
                icon="check"
                :style="{
                  color: '2b2a2a !important',
                }"
                width="25"
                height="25"
              ></b-icon>
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot v-if="getListCapacities.length > 0">
        <tr>
          <td :colspan="4" class="footer-matrix4">TOTAL</td>
          <b-td
            class="secondary-cell-matrix4 footer-matrix4"
            v-for="capacity in getListCapacities"
            :key="'capacity_matter-footer' + capacity.id"
          >
            <div
              v-b-tooltip.v-secondary.noninteractive="
                `Cantidad de ${$getVisibleNames(
                  'mesh.egressprofilematter',
                  false,
                  'Asignatura'
                )}`
              "
            >
              {{ getMatterCount(capacity.id) }}
            </div>
          </b-td>
        </tr>
      </b-tfoot>
    </b-table-simple>

    <b-table-simple
      v-if="getListCapacities.length > 0"
      bordered
      responsive
      striped
      class="table-capacity"
    >
      <b-thead>
        <b-tr>
          <b-th class="primary-cell-capacity" colspan="3">CAPACIDADES</b-th>
        </b-tr>

        <b-tr>
          <b-th class="primary-cell-capacity text-left" style="width: 40%"
            >DEFINICIÓN:</b-th
          >
          <b-th class="primary-cell-capacity text-left" style="width: 40%"
            >DESCRIPCIÓN:</b-th
          >
          <b-th class="primary-cell-capacity text-center" style="width: 20%">{{
            $getVisibleNames(
              "mesh.egressprofilematter",
              true,
              "Asignaturas"
            ).toUpperCase()
          }}</b-th>
        </b-tr>
      </b-thead>

      <b-tbody>
        <b-tr
          v-for="capacity in getListCapacities"
          :key="'capacity_footer' + capacity.id"
        >
          <b-td class="text-left">{{ capacity.name }}</b-td>
          <b-td>
            <div class="rich-text-content" v-html="capacity.description"></div>
          </b-td>
          <b-td>
            <b-button
              size="sm"
              v-b-tooltip.v-secondary.hover="
                capacity.length > 0 ? 'Ver Detalles' : ''
              "
              @click="$bvModal.show(`capacity-matters-modal-${capacity.id}`)"
            >
              <b-badge variant="secondary">
                {{ getMatterCount(capacity.id) }}
              </b-badge>
            </b-button>
            <!-- Modal al apretar boton de capacidades -->
            <b-modal
              :id="`capacity-matters-modal-${capacity.id}`"
              size="lg"
              hide-footer
            >
              <template #modal-title>
                <h6 class="mt-2">{{ capacity.name }}</h6>
              </template>
              <template>
                <b-table-simple responsive small bordered>
                  <b-thead>
                    <b-tr>
                      <b-th class="primary-cell-capacity-modal">
                        <div>Nivel del Plan</div>
                      </b-th>
                      <b-th class="primary-cell-capacity-modal">
                        <div>
                          {{
                            $getVisibleNames(
                              "mesh.egressprofilematter",
                              true,
                              "Asignaturas"
                            )
                          }}
                        </div>
                      </b-th>
                      <b-th class="primary-cell-capacity-modal">
                        <div>
                          {{
                            $getVisibleNames(
                              "manual.credits",
                              true,
                              "Créditos SCT Totales"
                            )
                          }}
                        </div>
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="egress_profile_matter in egressProfileMattersFilter.filter(
                        (x) => x.capacities.includes(capacity.id)
                      )"
                      :key="egress_profile_matter.id"
                    >
                      <b-td class="text-center">{{
                        egress_profile_matter.plan_level
                      }}</b-td>
                      <b-td>
                        <b-link
                          class="secondary-cell-matrix4"
                          :to="{
                            name: 'MatterProgramView',
                            params: { matter_id: egress_profile_matter.id },
                          }"
                          v-b-tooltip.v-secondary.noninteractive="
                            `Ir al ${$getVisibleNames(
                              'manual.matter_program',
                              false,
                              'Programa de la Asignatura'
                            )}`
                          "
                        >
                          {{ getMatterName(egress_profile_matter.matter) }}
                        </b-link>
                      </b-td>
                      <b-td class="text-center">
                        {{
                          egress_profile_matter.use_matter_values
                            ? (
                                matters.find(
                                  (x) => x.id == egress_profile_matter.matter
                                ) || {}
                              ).credits || 0
                            : egress_profile_matter.credits
                        }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </template>
            </b-modal>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "Matrix4",
  components: {
    // NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
  },
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      matters: names.MATTERS,
      egressProfileMatters: names.EGRESS_PROFILES_MATTERS,
      formation_lines: names.FORMATION_LINES,
      competences: names.COMPETENCES,
      profile_competences: names.PROFILE_COMPETENCES,
      capacities: names.CAPACITIES,
    }),
    egressProfileMattersFilter() {
      return this.egressProfileMatters
        .filter((x) => x.egress_profile == this.egress_profile_id)
        .sort((a, b) => a.order - b.order);
    },
    getListCapacitiesIds() {
      let allCapacities = [];
      this.egressProfileMattersFilter.forEach((x) => {
        allCapacities = allCapacities.concat(x.capacities);
      });
      return [...new Set(allCapacities)];
    },
    getListCapacities() {
      return this.capacities.filter((x) =>
        this.getListCapacitiesIds.includes(x.id)
      );
    },
  },
  methods: {
    getMatterCount(capacity_id) {
      return this.egressProfileMattersFilter.filter((x) =>
        x.capacities.includes(capacity_id)
      ).length;
    },
    getMatterName(matter_id) {
      const matter = this.matters.find((x) => x.id == matter_id);
      if (matter) {
        return matter.code + " - " + matter.name;
      } else return "";
    },
    getFormationLineName(formation_id) {
      const formation_line = this.formation_lines.find(
        (x) => x.id == formation_id
      );
      if (formation_line) {
        return formation_line.name;
      } else return "";
    },
    getCompetenceName(competence_id) {
      const competence = this.competences.find((x) => x.id == competence_id);
      if (competence) {
        const profile_competence = this.profile_competences.find(
          (x) =>
            x.competence == competence_id &&
            x.egress_profile == this.egress_profile_id
        );
        if (profile_competence)
          return profile_competence.order + ". " + competence.full_sentence;
        else return "";
      } else return "";
    },
  },
  watch: {},
  mounted() {},
  created() {},
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scope>
.container-title {
  font-size: var(--secondary-title-font-size);
  text-align: left;
}
.table-capacity {
  font-size: var(--secondary-font-size);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.primary-cell-matrix4 {
  background-color: var(--primary-color) !important;
  text-align: center;
  padding: 0.6rem;
  vertical-align: middle !important;
  font-weight: bold;
}
.primary-cell-capacity {
  background-color: var(--primary-color);
  color: black;
}
.primary-cell-capacity-modal {
  vertical-align: middle !important;
  text-align: center;
  background-color: var(--primary-color);
}
.footer-matrix4 {
  background-color: var(--primary-color);
  color: black;
  font-weight: bold;
}
.secondary-cell-matrix4 {
  vertical-align: middle !important;
}
.competence-cell {
  width: 25%;
  max-width: 25%;
}
</style>